
function WaveSvg(props) {
    if (!props.down) {

        return (
            <div id='projects' >
                <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150" >
                    <defs>
                        <linearGradient
                            id="gradient"
                            x1="50%"
                            y1="100%"
                            x2="50%"
                            y2="0%">
                            <stop offset="5%" stopColor="#ff690066"></stop><stop offset="95%" stopColor="#fcb90066"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,100 0,100 C 38.52752863579052,113.53503221513658 77.05505727158103,127.07006443027316 106,133 C 134.94494272841897,138.92993556972684 154.3072995494664,137.25477449404391 189,125 C 223.6927004505336,112.74522550595609 273.7157445305535,89.91083759355118 311,94 C 348.2842554694465,98.08916240644882 372.8297223283198,129.1018751317514 409,130 C 445.1702776716802,130.8981248682486 492.9653661561673,101.68166187944318 525,98 C 557.0346338438327,94.31833812055682 573.3088130470111,116.17147735047584 600,124 C 626.6911869529889,131.82852264952416 663.7993816557885,125.63242871865337 705,113 C 746.2006183442115,100.36757128134663 791.4936603298354,81.29880777491063 828,89 C 864.5063396701646,96.70119222508937 892.2259770248702,131.1723401817042 926,130 C 959.7740229751298,128.8276598182958 999.6024315706841,92.01183149827264 1039,81 C 1078.397568429316,69.98816850172736 1117.364296692394,84.78033382520528 1144,93 C 1170.635703307606,101.21966617479472 1184.9403816597398,102.86683320090627 1220,111 C 1255.0596183402602,119.13316679909373 1310.8741766686458,133.75233337116964 1351,133 C 1391.1258233313542,132.24766662883036 1415.5629116656771,116.12383331441518 1440,100 C 1440,100 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" className="transition-all duration-300 ease-in-out delay-150 path-0"></path>
                    <defs>
                        <linearGradient
                            id="gradient"
                            x1="50%"
                            y1="100%"
                            x2="50%"
                            y2="0%">
                            <stop offset="5%" stopColor="#ff690088"></stop><stop offset="95%" stopColor="#fcb90088"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,200 0,200 C 40.45759706408457,202.76312659677262 80.91519412816913,205.52625319354522 116,210 C 151.08480587183087,214.47374680645478 180.79682055140802,220.65811382259173 209,221 C 237.20317944859198,221.34188617740827 263.89752366619877,215.84129151608795 295,205 C 326.10247633380123,194.15870848391205 361.61308478379686,177.9767201130565 402,174 C 442.38691521620314,170.0232798869435 487.6501371986137,178.25182803168602 521,177 C 554.3498628013863,175.74817196831398 575.7863664217481,165.01596776019943 608,177 C 640.2136335782519,188.98403223980057 683.2043971143937,223.6843009275163 724,225 C 764.7956028856063,226.3156990724837 803.3960451206772,194.24682852973532 839,182 C 874.6039548793228,169.75317147026468 907.2114224028976,177.3283849535424 942,186 C 976.7885775971024,194.6716150464576 1013.7582652677318,204.43963165609497 1043,213 C 1072.2417347322682,221.56036834390503 1093.755516526175,228.91308842207764 1121,236 C 1148.244483473825,243.08691157792236 1181.2196686275684,249.9080146555945 1219,234 C 1256.7803313724316,218.0919853444055 1299.365808963552,179.4548529555444 1337,170 C 1374.634191036448,160.5451470444556 1407.317095518224,180.2725735222278 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" className="transition-all duration-300 ease-in-out delay-150 path-1"></path>
                    <defs>
                        <linearGradient
                            id="gradient"
                            x1="50%"
                            y1="100%"
                            x2="50%"
                            y2="0%">
                            <stop offset="5%" stopColor="#ff6900ff"></stop><stop offset="95%" stopColor="#fcb900ff"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,300 0,300 C 34.31907061412407,293.2342614037959 68.63814122824814,286.4685228075918 100,287 C 131.36185877175186,287.5314771924082 159.7665057011315,295.3601701734288 196,294 C 232.2334942988685,292.6398298265712 276.29583596722597,282.09079649869295 313,282 C 349.70416403277403,281.90920350130705 379.05015042996445,292.27664383179916 409,289 C 438.94984957003555,285.72335616820084 469.5035623129163,268.80262817411045 506,269 C 542.4964376870837,269.19737182588955 584.93560031837,286.51284347175925 617,295 C 649.06439968163,303.48715652824075 670.7540364136036,303.14599793885264 708,294 C 745.2459635863964,284.85400206114736 798.0482540272159,266.9031647728303 838,272 C 877.9517459727841,277.0968352271697 905.0529474775335,305.2413429698264 934,315 C 962.9470525224665,324.7586570301736 993.73995606265,316.1314633478642 1031,310 C 1068.26004393735,303.8685366521358 1111.987228271867,300.2328036387168 1148,306 C 1184.012771728133,311.7671963612832 1212.3111308498821,326.9373220972685 1244,332 C 1275.6888691501179,337.0626779027315 1310.768248328605,332.017907972209 1344,325 C 1377.231751671395,317.982092027791 1408.6158758356974,308.99104601389547 1440,300 C 1440,300 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" className="transition-all duration-300 ease-in-out delay-150 path-2"></path></svg>
            </div>
        )
    } else {
        return (
            <div className='rotate-180'>
                <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150" >
                    <defs><linearGradient id="gradient" x1="50%" y1="100%" x2="50%" y2="0%"><stop offset="5%" stopColor="#ff690066"></stop><stop offset="95%" stopColor="#fcb90066"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,100 0,100 C 38.52752863579052,113.53503221513658 77.05505727158103,127.07006443027316 106,133 C 134.94494272841897,138.92993556972684 154.3072995494664,137.25477449404391 189,125 C 223.6927004505336,112.74522550595609 273.7157445305535,89.91083759355118 311,94 C 348.2842554694465,98.08916240644882 372.8297223283198,129.1018751317514 409,130 C 445.1702776716802,130.8981248682486 492.9653661561673,101.68166187944318 525,98 C 557.0346338438327,94.31833812055682 573.3088130470111,116.17147735047584 600,124 C 626.6911869529889,131.82852264952416 663.7993816557885,125.63242871865337 705,113 C 746.2006183442115,100.36757128134663 791.4936603298354,81.29880777491063 828,89 C 864.5063396701646,96.70119222508937 892.2259770248702,131.1723401817042 926,130 C 959.7740229751298,128.8276598182958 999.6024315706841,92.01183149827264 1039,81 C 1078.397568429316,69.98816850172736 1117.364296692394,84.78033382520528 1144,93 C 1170.635703307606,101.21966617479472 1184.9403816597398,102.86683320090627 1220,111 C 1255.0596183402602,119.13316679909373 1310.8741766686458,133.75233337116964 1351,133 C 1391.1258233313542,132.24766662883036 1415.5629116656771,116.12383331441518 1440,100 C 1440,100 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" className="transition-all duration-300 ease-in-out delay-150 path-0"></path>
                    <defs><linearGradient id="gradient" x1="50%" y1="100%" x2="50%" y2="0%"><stop offset="5%" stopColor="#ff690088"></stop><stop offset="95%" stopColor="#fcb90088"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,200 0,200 C 40.45759706408457,202.76312659677262 80.91519412816913,205.52625319354522 116,210 C 151.08480587183087,214.47374680645478 180.79682055140802,220.65811382259173 209,221 C 237.20317944859198,221.34188617740827 263.89752366619877,215.84129151608795 295,205 C 326.10247633380123,194.15870848391205 361.61308478379686,177.9767201130565 402,174 C 442.38691521620314,170.0232798869435 487.6501371986137,178.25182803168602 521,177 C 554.3498628013863,175.74817196831398 575.7863664217481,165.01596776019943 608,177 C 640.2136335782519,188.98403223980057 683.2043971143937,223.6843009275163 724,225 C 764.7956028856063,226.3156990724837 803.3960451206772,194.24682852973532 839,182 C 874.6039548793228,169.75317147026468 907.2114224028976,177.3283849535424 942,186 C 976.7885775971024,194.6716150464576 1013.7582652677318,204.43963165609497 1043,213 C 1072.2417347322682,221.56036834390503 1093.755516526175,228.91308842207764 1121,236 C 1148.244483473825,243.08691157792236 1181.2196686275684,249.9080146555945 1219,234 C 1256.7803313724316,218.0919853444055 1299.365808963552,179.4548529555444 1337,170 C 1374.634191036448,160.5451470444556 1407.317095518224,180.2725735222278 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" className="transition-all duration-300 ease-in-out delay-150 path-1"></path>
                    <defs><linearGradient id="gradient" x1="50%" y1="100%" x2="50%" y2="0%"><stop offset="5%" stopColor="#ff6900ff"></stop><stop offset="95%" stopColor="#fcb900ff"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,300 0,300 C 34.31907061412407,293.2342614037959 68.63814122824814,286.4685228075918 100,287 C 131.36185877175186,287.5314771924082 159.7665057011315,295.3601701734288 196,294 C 232.2334942988685,292.6398298265712 276.29583596722597,282.09079649869295 313,282 C 349.70416403277403,281.90920350130705 379.05015042996445,292.27664383179916 409,289 C 438.94984957003555,285.72335616820084 469.5035623129163,268.80262817411045 506,269 C 542.4964376870837,269.19737182588955 584.93560031837,286.51284347175925 617,295 C 649.06439968163,303.48715652824075 670.7540364136036,303.14599793885264 708,294 C 745.2459635863964,284.85400206114736 798.0482540272159,266.9031647728303 838,272 C 877.9517459727841,277.0968352271697 905.0529474775335,305.2413429698264 934,315 C 962.9470525224665,324.7586570301736 993.73995606265,316.1314633478642 1031,310 C 1068.26004393735,303.8685366521358 1111.987228271867,300.2328036387168 1148,306 C 1184.012771728133,311.7671963612832 1212.3111308498821,326.9373220972685 1244,332 C 1275.6888691501179,337.0626779027315 1310.768248328605,332.017907972209 1344,325 C 1377.231751671395,317.982092027791 1408.6158758356974,308.99104601389547 1440,300 C 1440,300 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="url(#gradient)" className="transition-all duration-300 ease-in-out delay-150 path-2"></path></svg>
            </div>
        )
    }
}

export default WaveSvg
